import React, { useState } from "react";
import styled from "styled-components";
import { PageProps, graphql } from "gatsby";
import { BlocksContent, Breadcrumb } from "@global";
import { Maybe, Query } from "@graphql-types";
import Hero from "@shared/hero";
import {
  Container,
  HeroH2,
  GridContainer,
  GridColumnSpan,
  H2,
} from "@util/standard";
import Layout from "@shared/layout";
import SEO from "@shared/seo";
import { BreadcrumbPageContext } from "@util/types";
import {
  colors,
  fontSizes,
  fontWeights,
  IPAD_BREAKPOINT,
  MOBILE_BREAKPOINT,
} from "@util/constants";
import { navigate } from "gatsby";
import JotformEmbed from "react-jotform-embed";
import ContactUsPage from "./contactPageTemplate";

const ContentContainer = styled(Container)`
  width: 80%;
  flex-direction: column;
  margin: 5% auto;
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 84%;
  }
`;

const FeedBackBtn = styled.a<{ isSelected?: boolean }>`
  background-color: ${props =>
    props.isSelected ? colors.merlin : colors.transparent};
  color: ${props => (props.isSelected ? colors.white : colors.merlin)};
  font-size: ${fontSizes.p.alternate}px;

  letter-spacing: 0.08em;
  border: 1.5px solid ${colors.merlin};
  border-radius: 36px;
  font-weight: ${fontWeights.bold};
  padding: 14px 40px;
  width: fit-content;
  &:hover {
    opacity: 1;
  }
  @media only screen and (max-width: ${IPAD_BREAKPOINT}px) {
    font-size: ${fontSizes.button.mobile}px;
    padding: 11px 25px;
  }
`;
const BtnContainer = styled(Container)`
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
`;

interface Props extends PageProps {
  data: Query;
  pageContext: BreadcrumbPageContext;
}

const FeedbackPage = ({ pageContext, data }: Props) => {
  const sanityFeedbackPage = data.allSanityFeedbackPage.nodes[0];

  if (sanityFeedbackPage == null) {
    return null;
  }

  //assign the first button key on load
  let initialFilter = null;
  let initialBtnKey = null;
  if (
    sanityFeedbackPage &&
    sanityFeedbackPage?.feedbackType &&
    sanityFeedbackPage?.feedbackType[0]?.url
  ) {
    initialFilter = sanityFeedbackPage?.feedbackType[0]?.url;
  }

  if (
    sanityFeedbackPage &&
    sanityFeedbackPage?.feedbackType &&
    sanityFeedbackPage?.feedbackType[0]?._key
  ) {
    initialBtnKey = sanityFeedbackPage?.feedbackType[0]?._key;
  }

  const [dataFilter, setDataFilter] = useState(initialFilter);
  const [selectedBtn, setSelectedBtn] = useState(initialBtnKey);

  const handleClick = (
    urlJotform: Maybe<string> | undefined,
    keyBtn: Maybe<string> | null
  ) => {
    if (urlJotform) {
      setDataFilter(urlJotform);
      setSelectedBtn(keyBtn);
    }
  };

  return (
    <>
      <SEO seoData={sanityFeedbackPage.seo} />
      <Layout>
        <Hero height="auto" sanityHero={sanityFeedbackPage?.hero} />
        <Breadcrumb pageContext={pageContext} />
        <ContentContainer>
          <GridContainer repeat={6} mobileRepeat={1} tabletRepeat={1}>
            <GridColumnSpan gridColumnWidth={6} paddingBottom={20}>
              <a onClick={() => navigate(-1)}>&lt; Back</a>
            </GridColumnSpan>
            <GridColumnSpan gridColumnWidth={2}>
              <H2 fontFamily="Poppins">{sanityFeedbackPage?.feedbackTitle}</H2>
            </GridColumnSpan>
            <GridColumnSpan gridColumnWidth={6} paddingBottom={20}>
              <BtnContainer>
                {sanityFeedbackPage?.feedbackType &&
                  sanityFeedbackPage?.feedbackType?.map((fdType, i) => {
                    if (fdType == null) return null;

                    return (
                      <FeedBackBtn
                        isSelected={fdType?._key == selectedBtn}
                        key={fdType?._key}
                        onClick={() => handleClick(fdType?.url, fdType?._key)}
                      >
                        {fdType?.linktext}
                      </FeedBackBtn>
                    );
                  })}
              </BtnContainer>
            </GridColumnSpan>
            <GridColumnSpan gridColumnWidth={2}>
              <BlocksContent
                blocks={sanityFeedbackPage.FeedbackInfo?._rawBlocks}
              />
            </GridColumnSpan>
            <GridColumnSpan gridColumnWidth={4} width={100}>
              <JotformEmbed src={dataFilter} />
            </GridColumnSpan>
          </GridContainer>
        </ContentContainer>
      </Layout>
    </>
  );
};

export default FeedbackPage;

export const query = graphql`
  query allFeedbackQuery($iban: String) {
    allSanityFeedbackPage(filter: { region: { iban: { eq: $iban } } }) {
      nodes {
        hero {
          ...sanityHero
        }
        seo {
          ...sanitySeo
        }
        feedbackType {
          _key
          _type
          internallink
          linktext
          newwindow
          url
        }
        FeedbackInfo {
          _key
          blocks {
            style
            _type
            _rawChildren
            _key
          }
          _type
          _rawBlocks
        }
        feedbackTitle
      }
    }
  }
`;
